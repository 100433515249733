.dz-img {
  margin: 10px;
  float: left;
  width: 20%;
  height: 200px;
  object-fit: contain;
}

.fids_container {
  display: flex;
  height: calc(100vh - 90px);
}



.header {
  color: #cdb611;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  font-weight: 600;
}

table {
  border: none;
  text-align: left;
  padding: 5px;
}

tr:nth-child(2n+1) {
  background-color: black;
}

tr:nth-child(2n) {
  background-color: #262626;
}

td {
  font-size: 24px;
  color: white;
}

th {
  color: white;
  font-size: 36px;
}

/* td{
  padding-left: 20px;
} */

.title {
  font-size: 60px;
  color: black;
}

.time {
  color: black;
  font-size: 60px;
  text-align: right;
}

.logo {
  margin-left: 10px;
}

.back {
  background-color: #ccc;
}

.button {
  border-radius: 30px;
  border: none;
  padding: 6px 14px;
  font-weight: 600;
}

/* Status Button */
.Cancelled {
  background-color: #ffd0d0;
  color: #ff4e4e;
}

.Departed {
  background-color: #e7edff;
  color: #444444;
}

.Arrived {
  background-color: #c3f1cd;
  color: #1bb521;
}

.Delayed {
  background-color: #f2efd6;
  color: #d8d096;
}

.overlay {
  position: absolute;
  bottom: 0;
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.5);
  /* Black see-through */
  color: #f1f1f1;
  width: 100%;
  transition: .5s ease;
  opacity: 0;
  color: white;
  font-size: 30px;
  padding-bottom: 40px;
  text-align: center;
}

.carouselContainer {
  position: relative;
}

.carouselContainer:hover .overlay {
  opacity: 1;
}

.fids_box {
  overflow-x: auto;
}

.number{
  width: calc((100vh - 200px)/ 2);
}

.fids_carousel,
.carouselContainer {
  height: calc( 100vh - 200px);
  width: calc((100vh - 200px)/ 2);
}

.carousel_image {
  height: calc( 100vh - 200px);
  width: calc((100vh - 200px)/ 2);
  object-fit: fill;
}

@media only screen and (max-width: 720px) {
  .fids_container {
    display: block;
  }

  .fids_box {
    overflow-x: scroll;
  }

  .fids_carousel,
  .carouselContainer {
    height: 20vh;
  }

  .carousel_image {
    height: 20vh;
    object-fit: fill;
  }
}
